import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import queenMabImage from '../../assets/images/deities/crownofice.jpg';
import './DeityPathPage.css';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

const QueenMabFrozenVeil = () => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="deity-path-page">
      <div className="deity-path-hero">
        <img 
          src={queenMabImage} 
          alt="Queen Mab: Frozen Veil Path" 
          className="deity-path-background"
          onClick={() => setShowFullImage(true)}
          style={{ cursor: 'pointer' }}
        />
        <div className="deity-path-title">
          <h1>Queen Mab: The Frozen Veil Path</h1>
          <div className="menu-container">
            <div ref={menuRef} className={`veiled-paths ${menuOpen ? 'active' : ''}`}>
              <h2 onClick={toggleMenu}>
                Veiled Paths
                <ChevronDown size={20} />
              </h2>
              <div className="veiled-links">
                <Link to="/veiled-ascension" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  ← Return to Veiled Ascension
                </Link>
                <Link to="/deities/caelumbris/shadowed" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Caelumbris: Shadowed
                </Link>
                <Link to="/deities/firstborn/soulbound" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Firstborn: Soulbound
                </Link>
                <Link to="/deities/libris/knowledge" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  L.I.B.R.I.S.: Knowledge
                </Link>
                <Link to="/deities/nebula/mistwalker" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Nebula: Mistwalker
                </Link>
                <Link to="/deities/queen-titania/wildgrowth" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Queen Titania: Wildgrowth
                </Link>
                <Link to="/deities/sacrathor/dominion" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Sacrathor: Dominion
                </Link>
                <Link to="/deities/valdis/justice" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Valdís: Justice
                </Link>
                <Link to="/deities/wildscar/emberfury" className="veiled-link" onClick={() => setMenuOpen(false)}>
                  Wildscar: Ember Fury
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block requirements-section">
        <div className="edicts">
          <h2>Edicts</h2>
          <ul>
            <li><strong>Preserve Winter's Order:</strong> Protect and control the cycles of winter, ensuring the world is prepared for renewal.</li>
            <li><strong>Wield Frost with Precision:</strong> Use cold and ice strategically as both weapons and shields in your endeavors.</li>
            <li><strong>Plan with Patience:</strong> Value strategic planning and calculated action, recognizing that every move has consequences.</li>
          </ul>
        </div>
        
        <div className="requirements">
          <h2>Requirements</h2>
          <ul>
            <li><strong>Flame Forbidden:</strong> Under no circumstances may you touch, use, or cast flames.</li>
            <li><strong>Calculated Action:</strong> Always consider the long-term implications of your actions and prioritize the preservation of balance.</li>
            <li><strong>Uncover Hidden Truths:</strong> Seek to uncover hidden truths and expose deceptions that threaten the stability of the realm.</li>
          </ul>
        </div>
      </div>

      <div className="content-block dark-block">
        <h2 className="blue-text">Queen Mab: Frozen Veil</h2>
        <p>
          Queen Mab stands as a counterbalance to her sister Titania's chaotic growth, embodying the calculated precision of winter. Her domain—a crystalline expanse of beauty and cold—mirrors her unyielding resolve. Those who follow her channel her strength, wielding frost and stillness as extensions of her indomitable will. Legends tell of Mab as a deity of necessity, teaching her followers that winter is not an end but a time of preparation and endurance. Through her guidance, her adherents learn to survive, adapt, and shape the battlefield with the very essence of frost.
        </p>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>Queen Mab's Domain</h3>
        </div>
        <p>
          Queen Mab's realm is a frozen expanse of breathtaking beauty and perilous challenges. Towering glaciers and endless snowfields stretch beneath a pale, eternal sky, while hidden caverns of shimmering ice conceal secrets of ages past. Frost-laden winds sweep across the landscape, carrying whispers of her icy court's secrets. In this land, shadows and frost intertwine—every surface bears the mark of winter's necessity, a season of stillness, reflection, and preparation for the rebirth to come.
        </p>
      </div>

      <h2>Levels and Abilities</h2>
      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 3: FROSTBORN INITIATE</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Core Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Frost Aura:</strong> The air around you is frigid—a tangible presence of icy energy that causes frost to crystallize on surfaces. Enemies within 10 feet of you have their movement speed reduced by 10 feet as their steps falter against the biting cold.</li>
            <li><strong>Natural Armor:</strong> A thin layer of crystalline ice constantly forms and reforms over your flesh, glinting like diamond dust in the light. When unarmored, your AC equals 16 + your Dexterity modifier, an unyielding defense born of Queen Mab's enduring frost.</li>
            <li><strong>Cold Resistance:</strong> The freezing chill of winter flows through your veins—a gift from Queen Mab herself. You gain resistance to cold damage, enabling you to thrive in frigid conditions.</li>
            <li><strong>Bonus Health:</strong> Queen Mab's wintry blessing fortifies your vitality, granting you an additional 1 hit point per level.</li>
          </ul>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Auxiliary Features</h3>
          </div>
          <ul className="specializations-list">
            <li><strong>Frozen Ground:</strong> You can cause the ground in a 15-foot radius centered on you to become coated in ice. Creatures of your choice within this area must succeed on a Dexterity saving throw (DC = 8 + your proficiency bonus + your Constitution modifier) or fall prone. The ice lasts for 1 minute, creating difficult terrain for all creatures except you.<br/>
            <em>Usage: A number of times equal to your proficiency bonus per long rest.</em></li>
            <li><strong>Icebound Wall:</strong> As an action, you can create a wall of ice 5 feet long per level. The wall is 5 feet high and 1 foot thick and lasts for 1 minute or until destroyed. Each 5-foot section has AC 10 and 10 hit points. The wall provides three-quarters cover and creates difficult terrain on either side.<br/>
            <em>Usage: A number of times equal to your proficiency bonus per long rest.</em></li>
          </ul>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 5: GLACIAL ENVOY</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Frostbite Strike</h3>
          </div>
          <p>When you hit a creature with a melee attack, you can channel the biting chill of winter into your blow. The target takes an extra 1d6 cold damage, and it must succeed on a Constitution saving throw (DC = 8 + your proficiency bonus + your Constitution modifier) or have its movement speed reduced by 10 feet until the start of its next turn.</p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Glacial Ward</h3>
          </div>
          <p>As a reaction when you or an ally within 10 feet is hit by an attack, you can invoke the protective might of frost. The triggering attack deals half damage, and the target gains temporary hit points equal to your proficiency bonus.</p>
          <p><em>Usage: A number of times equal to your proficiency bonus per long rest.</em></p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 10: ENHANCED FROZEN GROUND AND GLACIAL BASTION</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Enhanced Frozen Ground (Winter's Embrace)</h3>
          </div>
          <p>When you activate Frozen Ground, its effects are enhanced as follows:</p>
          <ul className="specializations-list">
            <li>The radius increases from 15 feet to 20 feet centered on you.</li>
            <li>In addition to the original effect (creatures must succeed on a Dexterity saving throw or fall prone, with the ice lasting for 1 minute and creating difficult terrain for all creatures except you), any creature that fails its saving throw takes now also takes 3d6 cold damage and has its movement speed reduced by 10 feet until the start of its next turn.</li>
            <li>On a successful save, the creature takes half of the extra cold damage and suffers no movement penalty.</li>
            <li>A creature affected by this additional effect may repeat the saving throw at the end of each of its turns to end the movement penalty early.</li>
          </ul>
          <p><em>Usage: This enhanced effect applies each time you use Frozen Ground, which you can activate a number of times equal to your proficiency bonus per long rest.</em></p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Glacial Bastion</h3>
          </div>
          <p>As an action, you conjure a dome of solid ice centered on yourself with a 10-foot radius. This bastion lasts for 10 minutes or until you dismiss it as a bonus action. All allies inside gain three-quarters cover and a bonus to AC equal to your proficiency bonus. Additionally, the dome absorbs the first instance of damage each round for each ally inside—negating damage up to 1d6 per instance.</p>
          <p><em>Usage: Once per long rest.</em></p>
        </div>
      </div>

      <div className="trait">
        <div className="trait-header">
          <h3>LEVEL 15: CHAMPION OF THE FROZEN VEIL</h3>
        </div>
        <div className="trait">
          <div className="trait-header">
            <h3>Absolute Zero</h3>
          </div>
          <p>As an action, choose a point you can see within 60 feet. A 30-foot-radius sphere of bone-chilling cold erupts around that point. Each creature in the sphere must make a Dexterity saving throw (DC = 8 + your proficiency bonus + your Constitution modifier). On a failure, a creature takes 8d8 cold damage and is paralyzed with frostbite for 1 round; on a success, it takes half damage and is not paralyzed. Creatures affected by paralysis may attempt a new saving throw at the end of each of their turns to end the effect.</p>
          <p><em>Usage: A number of times equal to your proficiency bonus per long rest.</em></p>
        </div>

        <div className="trait">
          <div className="trait-header">
            <h3>Eternal Winter – Living Ice</h3>
          </div>
          <p>At 15th level, you assume the aspect of winter incarnate and become living ice. You transform into a crystalline, resilient embodiment of frost. You gain the following benefits:</p>
          <ul className="specializations-list">
            <li><strong>Cold Immunity:</strong> You become immune to cold damage.</li>
            <li><strong>Increased Mobility:</strong> Your movement speed is doubled.</li>
            <li><strong>Heat Suppression:</strong> You gain resistance to fire damage.</li>
            <li><strong>Living Ice Resilience:</strong> Your body, now composed of living ice, grants you resistance to non-magical bludgeoning, slashing, and piercing damage.</li>
          </ul>
        </div>
      </div>

      {/* Image Modal */}
      {showFullImage && (
        <div className="image-modal" onClick={() => setShowFullImage(false)}>
          <div className="modal-image-container">
            <img 
              src={queenMabImage} 
              alt="Queen Mab: Frozen Veil Path"
              className="modal-image"
            />
            <button 
              className="modal-close"
              onClick={(e) => {
                e.stopPropagation();
                setShowFullImage(false);
              }}
            >
              ✕
            </button>
            <a 
              href={queenMabImage}
              download="QueenMab.png"
              onClick={(e) => e.stopPropagation()}
              className="modal-download"
            >
              ⤓
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default QueenMabFrozenVeil;

